<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> NUEVO USUARIO </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'usuariosPanel' }"
                    >Usuarios</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'usuariosCrearPanel' }"
                    >Crear</router-link
                  >
                </p>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-form @submit.prevent="guardar()" :disabled="processForm">
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-select
                    outlined
                    dense
                    item-text="name"
                    item-value="name"
                    :items="arrayRoles"
                    label="Rol (*)"
                    v-model="usuario.rol"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Correo(*)"
                    v-model="usuario.correo"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    :append-icon="showIptPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showIptPassword ? 'text' : 'password'"
                    label="Contraseña (*)"
                    v-model="usuario.clave"
                    @click:append="showIptPassword = !showIptPassword"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbEstado"
                    label="Estado (*)"
                    outlined
                    v-model="usuario.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'usuariosPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      usuario: {
        rol: "",
        clave: "",
        correo: "",
        estado: 1,
      },
      arrayRoles: [],
      showIptPassword: false,
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  methods: {
    getDatos() {
      this.axios({
        method: "GET",
        url: "api/panel/usuarios/create",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let roles = response.data.roles;

            this.arrayRoles = roles;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    guardar() {
      this.alertError = false;
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/usuarios",
        data: this.serialize(this.usuario),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "usuariosPanel",
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al guardar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },

  created() {
    if (!this.validarPermiso("usuarios.crear")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);
    this.getDatos();
  },
};
</script>
